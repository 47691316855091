<template>
  <b-card
    :title="data.name"
    style="max-height: 100%;height: -webkit-fill-available;"
  >
    <b-overlay
      variant="transparent"
      :show="show"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <div class="d-flex justify-content-between flex-wrap mb-2">
        <div>
          <span class="text-muted">{{ data.chartName }} </span>
        </div>
      </div>
      <div class="pt-25 ml-75">
        <h3>
          <b-badge variant="light-secondary">
            <span
              :key="data.chartName"
              :class="'font-weight-bolder align-middle '+data.chartName"
            >{{
              bytesToSize(storage) }}
            </span>
          </b-badge>
        </h3>
      </div>
    </b-overlay>
  </b-card>
</template>
<script>

// import "flatpickr/dist/themes/material_blue.css";
import { BCard, BBadge, BOverlay } from 'bootstrap-vue'
import CloudWatchService from '../services/CloudWatchService'

const cloudWatchServiceOptions = {
  period: 0,
  backfillMinutes: 2 * 60,
  refreshMinutes: 5,
  startDate: '',
  endDate: '',
  serverUrl: '',
  apikey: '',
}
export default {
  components: {
    BCard,
    BOverlay,
    BBadge,
  },
  props: {
    data: {
      default: [],
    },
    valores: {
      default: [],
    },
  },
  data() {
    return {
      service: null,
      task: null,
      storage: null,
      metrics: [],
      media: false,
      show: false,
      startDate: '',
      endDate: '',
      renderiza: 0,
      serverUrl: this.data.serverUrl,
      period: 86400,
      apikey: this.data.apikey,
      percentErrorRate: null,
      messagesPerHour: null,
      option: {
        xAxisData: [],
        series: [],
      },
    }
  },
  watch: {
    valores() {
      const [start, end, period] = this.valores
      this.startDate = start
      this.endDate = end
      this.period = period
    },
    endDate() {
      this.fillData()
    },

    service: {
      handler() {
        this.percentErrorRate = (this.service.datasets.tagged('errors').sum()
            / this.service.datasets.tagged('singleValue').sum())
          * 100
        this.getSize(this.service.datasets.tagged('singleValue').asArray())
      },
      deep: true,
    },
  },
  mounted() {
    const [start, end, period] = this.valores
    this.startDate = start
    this.endDate = end
    this.period = period
    this.fillData()
  },

  beforeDestroy() {
    this.service.stop()
  },
  methods: {
    bytesToSize(bytes) {
      if (bytes === null) return '--'
      if (bytes === 0) return '0 Bytes'
      if (bytes === 1) return '1 Byte'
      if (bytes === -1) return '-1 Byte'

      const bytesRes = Math.abs(bytes)

      const orderOfMagnitude = (10 ** 3)
      const abbreviations = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytesRes) / Math.log(orderOfMagnitude))
      let result = (bytesRes / (orderOfMagnitude ** i))

      if (bytesRes < 0) {
        result *= -1
      }

      if (result >= 99.995 || i === 0) {
        return `${result.toFixed(0)} ${abbreviations[i]}`
      }
      return `${result.toFixed(2)} ${abbreviations[i]}`
    },

    fillData() {
      this.show = true

      this.metrics = [
        {
          id: `single${this.data.id}`,
          tags: ['singleValue'],
          dimensionName: this.data.dimensionName,
          dimensionValue: this.data.dimensionValue,
          dimensionName2: this.data.dimensionName2,
          dimensionValue2: this.data.dimensionValue2,
          stat: this.data.stat === 'SUM' ? 'Sum' : 'Maximum',
          name: this.data.chartName,
          namespace: this.data.namespace,
        },
      ]

      cloudWatchServiceOptions.startDate = this.startDate
      cloudWatchServiceOptions.endDate = this.endDate
      cloudWatchServiceOptions.serverUrl = this.serverUrl
      cloudWatchServiceOptions.period = this.period
      cloudWatchServiceOptions.apikey = this.apikey

      this.service = new CloudWatchService(
        cloudWatchServiceOptions,
        this.metrics,
      )
    },
    clickCalendar(id) {
      document.getElementById(id).click()
    },

    getSize(data) {
      if (data.length !== 0) {
        this.storage = data[0].data[0].y
        this.$emit('getTotal', [this.storage, this.data.chartName, 1])
      }

      this.show = false
    },
  },
}
</script>

<style  lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#Balance {
  position: relative;
  box-sizing: border-box;
  padding: 1em 1em 1.5em 1em;
  min-height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(150px, 1fr);
  grid-gap: 1em;
  grid-auto-flow: row dense;
}

.updated {
  font: 1em "Nunito";
  padding-right: 1em;
  line-height: 1.5em;
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
