import axios from '@axios'
import DatasetArray from './DatasetArray'

export default class CloudWatchService {
  constructor({
    period = 3600, /* backfillMinutes = 120, */ refreshMinutes = 5, startDate = '', endDate = '', serverUrl = '', apikey = '',
  } = {}, metrics) {
    this.metrics = metrics
    this.period = period

    this.startDate = startDate.toISOString()
    this.endDate = endDate.toISOString()

    this.serverUrl = serverUrl
    this.apikey = apikey
    this.refreshMinutes = refreshMinutes
    this.maxDatapoints = 100800
    this.updatedAt = null
    this.datasets = new DatasetArray()
    this.start()
    this.tags = {}
  }

  start() {
    this.update()
    this.task = setInterval(this.update.bind(this), this.refreshMinutes * 60000)
    this.stop()
  }

  stop() {
    clearInterval(this.task)
  }

  async update() {
    const options = {
      url: this.serverUrl,
      method: 'post',
      headers: {
        'X-Api-Key': this.apikey,
      },
      data: {
        start: this.startDate,
        end: this.endDate,
        period: this.period,
        metrics: this.metrics,
      },
    }

    try {
      const response = await axios.request(options)

      this.updatedAt = new Date() // Doesn't reach if request failed
      this.appendData(response.data)
    } catch (error) {
      console.log(error)
    } // eslint-disable-line no-empty
    return this.data
  }

  appendData(newDatasets) {
    newDatasets.forEach(newDataset => {
      this.datasets.upsert(this.tagAndLabel(newDataset))
    })
    this.datasets.removeDataDuplicates(this.maxDatapoints)
  }

  tagAndLabel(dataset) {
    // Add tags and labels from metrics objects
    const metric = this.metrics.find(m => m.id === dataset.id)
    return typeof metric === 'undefined' ? dataset : Object.assign(dataset, {
      tags: metric.tags,
      label: metric.label,
      secondaryAxis: metric.secondaryAxis || false,
    })
  }
}
