<template>
  <e-charts
    ref="line"
    autoresize
    :options="option"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import theme from './theme.json'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      option: {
        title: {
          text: 'Stacked area chart',
        },
        tooltip: {
          trigger: 'axis',
          
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
          axisLabel: {
            margin: 10,
            textStyle: {
              fontSize: 14
            }
          },
        },

        legend: {
          visible: true,
          position: 'Top',
          textStyle: {
              fontSize: 16
            }
        },
        grid: {
          width: '85%',
          /* left: '40px',
          right: '4%',
          containLabel: false, */
          show: false,

        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            name: 'Periodo',
            nameLocation: 'end',
            nameTextStyle: {
              fontSize: 15,
              color: '#7367f0',
              fontWeight: 'bold',
              padding: [0, 0, 0, -10],
            },
            data: this.optionData.xAxisData,
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: 'Bytes',
            nameLocation: 'end',
            splitNumber: 5,
            splitLine: { show: false },
            nameTextStyle: {
              align: 'center',
              fontSize: 15,
              padding: [0, 15, 0, 0],
              fontWeight: 'bold',
              color: '#7367f0',
            },
            axisLabel: {
              showMinLabel: true,
              hideOverlap: true,
              formatter: '{bytes} Bytes',
              formatter: function (bytes, index) {

                if (bytes == null) return "--";
                if (bytes == 0) return '0 Bytes';
                if (bytes == 1) return '1 Byte';
                if (bytes == -1) return '-1 Byte';

                var bytes = Math.abs(bytes)

                var orderOfMagnitude = Math.pow(10, 3);
                var abbreviations = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

                var i = Math.floor(Math.log(bytes) / Math.log(orderOfMagnitude));
                var result = (bytes / Math.pow(orderOfMagnitude, i));

                if (bytes < 0) {
                  result *= -1;
                }


                if (result >= 99.995 || i == 0) {
                  return result.toFixed(0) + ' ' + abbreviations[i];
                } else {
                  return result.toFixed(2) + ' ' + abbreviations[i];
                }
              }
            },
          },
        ],
        series: this.optionData.series,
      },
    }
  },
  methods: {
    bytesToSize(bytes) {
      if (bytes == null) return "--";
      if (bytes == 0) return '0 Bytes';
      if (bytes == 1) return '1 Byte';
      if (bytes == -1) return '-1 Byte';

      var bytes = Math.abs(bytes)

      var orderOfMagnitude = Math.pow(10, 3);
      var abbreviations = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      var i = Math.floor(Math.log(bytes) / Math.log(orderOfMagnitude));
      var result = (bytes / Math.pow(orderOfMagnitude, i));

      if (bytes < 0) {
        result *= -1;
      }

      if (result >= 99.995 || i == 0) {
        return result.toFixed(0) + ' ' + abbreviations[i];
      } else {
        return result.toFixed(2) + ' ' + abbreviations[i];
      }

    },
  }
}
</script>

<style>
.echarts {
  width: 100% !important;
  left: -20px;
}
</style>
  