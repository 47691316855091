<template>
  <section id="cloudwatch">
    <div v-if="buscado">
      <b-row>
        <b-col
          md="5"
          class="pb-3 pb-md-0"
        >
          <h1>CDN</h1>
          <h5 class="text-primary">
            {{ $t('cdnMetrics') }}
          </h5>
        </b-col>
        <b-col md="7">
          <div style="text-align: end;">
            <div class="d-flex">
              <b-button
                class="m-1 widthCard"
                :disabled="show"
                :variant="timePeriod == 'hour' ? 'primary' : 'outline-primary'"
                size="sm"
                @click="timePeriod = 'hour'"
              >
                {{ $t('today') }}
              </b-button>
              <b-button
                class="m-1 widthCard"
                :disabled="show"
                :variant="timePeriod == '3days' ? 'primary' : 'outline-primary'
                "
                size="sm"
                @click="timePeriod = '3days'"
              >
                3 {{ $t('days') }}
              </b-button>
              <b-button
                class="m-1 widthCard"
                :disabled="show"
                :variant="timePeriod == '7days' ? 'primary' : 'outline-primary'"
                size="sm"
                @click="timePeriod = '7days'"
              >
                7
                {{ $t('days') }}
              </b-button>
              <b-button
                class="m-1 widthCard"
                :disabled="show"
                :variant="timePeriod == '15days' ? 'primary' : 'outline-primary'
                "
                size="sm"
                @click="timePeriod = '15days'"
              >
                15 {{ $t('days') }}
              </b-button>
              <b-button
                class="m-1 widthCard"
                :disabled="show"
                :variant="timePeriod == '30days' ? 'primary' : 'outline-primary'
                "
                size="sm"
                @click="timePeriod = '30days'"
              >
                30 {{ $t('days') }}
              </b-button>
              <b-button
                class="m-1 widthCard"
                :disabled="show"
                :variant="timePeriod == 'month' ? 'primary' : 'outline-primary'
                "
                size="sm"
                @click="timePeriod = 'month'"
              >
                {{ $t(`meses.${actualMonth}`) }}
              </b-button>
              <b-button
                :disabled="show"
                :variant="timePeriod == 'calendar' || timePeriod == null ? 'primary' : 'outline-primary'"
                size="sm"
                class="m-1 cursor-pointer  widthCard"
                @click="timePeriod == 'calendar' ? timePeriod = null : timePeriod = 'calendar'"
              >
                <feather-icon
                  icon="CalendarIcon"
                  size="20"
                />
                <flat-pickr
                  id="calendar"
                  v-model="time"
                  style="visibility: hidden; width: 0; height: 0;"
                  :config="{
                    enableTime: false,
                    mode: 'range',
                    enableSeconds: true,
                    dateFormat: 'Y-m-d H:i:S',
                    time_24hr: true,

                  }"
                />
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-2 d-flex justify-content-end">
        <b-col
          class="d-flex justify-content-end"
          md="4"
        >
          <span class="mr-2">{{ fechaFormato(startDate) }} - {{ fechaFormato(endDate) }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-alert
            v-model="warningLimit"
            class="p-1"
            variant="danger"
            style="text-align: center;"
          >
            <span>
              <feather-icon
                icon="AlertTriangleIcon"
                size="20"
              />
              {{ $t('exces') + " " + bytesToSize(exceso) }}
              <feather-icon
                :icon="'DownloadCloudIcon'"
                class="ml-2"
                size="20"
              />
            </span>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-alert
            v-model="warningLimitSize"
            class="p-1"
            variant="danger"
            style="text-align: center;"
          >
            <span>
              <feather-icon
                icon="AlertTriangleIcon"
                size="20"
              />
              {{ $t('limitDown') + " " + bytesToSize(excesoSize) }}
              <feather-icon
                :icon="'HardDriveIcon'"
                class="ml-2"
                size="20"
              />
            </span>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          {{ $t('traffic') }}: {{ bytesToSize(cdnTrafficLimit * Math.pow(1000, 3)) }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          {{ $t('dataContra') }}: {{ bytesToSize(sizeFileLimit * Math.pow(1000, 2)) }}
        </b-col>
      </b-row>
      <div
        v-if="value.length == 0 && graphic.length == 0"
        class="mt-2"
      >
        <b-row>
          <b-col>
            <b-jumbotron
              bg-variant="transparent"
              border-variant="primary"
              header="No existen datos"
            >
              <p class="text-primary">
                {{ $t('noGraphicCon') }}
              </p>
            </b-jumbotron>
          </b-col>
        </b-row>
      </div>
      <div
        v-else
        class="mt-2"
      >
        <b-overlay
          variant="transparent"
          style="text-align:center;"
          no-center
          :show="show"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <div
            v-if="value.length != 0"
            style="text-align: initial;"
          >
            <b-row
              :key="renderiza"
              class="tarjeta"
            >
              <b-col
                v-for="n, index in chartName"
                v-show="totalName[n] > 1"
                :key="index"
                style="max-width: fit-content;"
              >
                <b-card class="cartas ">
                  <b-card-title>
                    {{ n }}
                  </b-card-title>

                  <h2 class="d-flex justify-content-between flex-wrap">
                    <div class="mr-2">
                      <span class="text-muted">TOTAL </span>
                    </div>
                    <div>
                      <span class="font-weight-bolder align-middle"> {{ bytesToSize($data[n]) }}
                      </span>
                    </div>
                  </h2>

                  <feather-icon
                    :icon="n.toLowerCase().includes('download') ? 'DownloadCloudIcon' : n.toLowerCase().includes('size') ? 'HardDriveIcon' : n.toLowerCase().includes('upload') ? 'UploadCloudIcon' : 'CloudIcon'"
                    class="ml-2 mb-1"
                    size="30"
                  />
                </b-card>
              </b-col>
            </b-row>

            <!-- style="max-width: fit-content;" -->
            <b-row
              v-for="n in chartName"
              :id="n"
              :key="n"
            >
              <div
                v-for="datoValue, i in value"
                :key="n + '_' + i"
              >
                <b-col
                  v-if="n.includes(datoValue.chartName)"
                  :id="n + '_' + i"
                >
                  <single
                    :data="datoValue"
                    :valores="valores"
                    @getTotal="sumTot"
                  />
                </b-col>
              </div>
            </b-row>
          </div>
          <div
            v-for="value in Object.keys(graphicsObj)"
            :key="value"
          >
            <b-row
              v-if="graphic.length != 0"
              style="text-align: initial;"
            >
              <b-col>
                <graphic
                  v-if="value != 0"
                  :type="value"
                  :data="graphicsObj[value]"
                  :valores="valores"
                />
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </div>
    </div>
  </section>
</template>
<script>
import {
  BRow, BCol, BJumbotron, BCard, BCardTitle, BButton, BOverlay, BAlert,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'

import axios from '@axios'
import { messageError } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import single from './views/SingleValue.vue'
import graphic from './views/Graphic.vue'
// import graphic from "./views/Graphic copy.vue";

export default {
  components: {
    flatPickr,
    BRow,
    BCard,
    BCardTitle,
    BCol,
    BOverlay,
    BAlert,
    graphic,
    BButton,
    single,
    BJumbotron,
  },
  data() {
    return {
      awsKpi: [],
      period: 86400,
      time: null,
      valores: [],
      sum: false,
      renderiza: 0,
      value: [],
      graphic: [],
      graphicsObj: [{}],
      chartName: [],
      totalName: [{}],
      resName: [{}],
      buscado: false,
      startDate: '',
      show: false,
      warningLimit: false,
      endDate: '',
      timePeriod: 'month',
      actualMonth: null,
      cdnTrafficLimit: 0,
      sizeFileLimit: 0,
      testing: false,
      testingSize: false,
      exceso: null,
      excesoSize: null,
      warningLimitSize: false,

    }
  },
  watch: {
    renderiza() {
      let mostrar = false

      this.chartName.forEach(element => {
        if (this.$data[element] == null) {
          mostrar = true
        }
      })
      this.show = mostrar
      // tráfico de almacenamiento
      if (this.resName.BucketSizeBytes === 0 && !this.testingSize) {
        if (this.sizeFileLimit !== 0) {
          if (this.chartName.includes('BucketSizeBytes') && this.$data.BucketSizeBytes != null) {
            this.testingSize = true
            const tot = this.sizeFileLimit * (1000 ** 2)

            if (this.$data.BucketSizeBytes > tot) {
              this.excesoSize = this.$data.BucketSizeBytes - tot

              this.warningLimitSize = true

              this.$swal({
                title: this.$t('limit'),
                text: `Se ha superado el límite configurado con un exceso de ${this.bytesToSize(this.excesoSize)}, esto puede afectar a la cuota mensual de almacenamiento`,
                icon: 'warning',
                confirmButtonText: this.$t('code.accept'),
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonStyling: false,
              }).then(() => {

              })
            } else {
              this.warningLimitSize = false
            }
          }
        }
      }

      // Tráfico de descarga

      if (this.resName.BytesDownloaded === 0 && !this.testing) {
        if (this.cdnTrafficLimit !== 0) {
          if (this.chartName.includes('BytesDownloaded') && this.$data.BytesDownloaded != null) {
            this.testing = true
            const bytesTotal = this.cdnTrafficLimit * (1000 ** 3)

            if (this.$data.BytesDownloaded > bytesTotal) {
              this.exceso = this.$data.BytesDownloaded - bytesTotal

              this.warningLimit = true

              this.$swal({
                title: this.$t('limit'),
                text: this.$t('excesLimit', { exceso: this.bytesToSize(this.exceso) }),
                icon: 'warning',
                confirmButtonText: this.$t('code.accept'),
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              }).then(() => {

              })
            } else {
              this.warningLimit = false
            }
          }
        }
      }
    },
    timePeriod() {
      this.chartName.forEach(key => {
        this.$data[key] = null
      })
      this.startDate = null
      this.endDate = null

      this.testing = true
      this.testingSize = true

      this.resName = this.totalName.slice()
      Object.keys(this.totalName).forEach(key => {
        this.resName[key] = this.totalName[key]
      })

      switch (this.timePeriod) {
        case 'hour':
          this.show = true
          this.period = null

          this.getTimeNow()

          break

        case '3days':
          this.show = true
          this.period = null

          this.getTime(3)

          break

        case '7days':
          this.show = true
          this.period = null

          this.getTime(7)

          break
        case '15days':
          this.show = true
          this.period = null

          this.getTime(15)
          break
        case '30days':
          this.show = true
          this.period = null

          this.getTime(30)

          break
        case 'month':
          this.show = true
          this.period = null
          this.testing = false
          this.testingSize = false

          this.getMonth()

          break
        case 'calendar':
        case 'null':
          this.period = null

          this.clickCalendar()
          break
        default:

          break
      }
    },
    period() {
      if (this.period != null) {
        this.valores = [this.startDate, this.endDate, this.period]
      }
    },
    time() {
      try {
        const separacion = this.time.split('to')
        const i = new Date(separacion[0])
        const f = separacion[1] != null ? new Date(separacion[1]) : null

        if (f != null) {
          this.show = true

          if (i.getTime() === f.getTime()) {
            const start = i

            this.startDate = new Date(start.setHours(0, 0, 0, 0))

            const end = f

            this.endDate = new Date(end.setHours(23, 59, 59, 999))
          } else {
            this.startDate = new Date(i.setHours(0, 0, 0, 0))
            this.endDate = new Date(f.setHours(23, 59, 59, 999))
          }

          this.getPeriodDate()
        }
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },

  },
  mounted() {
    this.monthNow()
  },
  methods: {
    monthNow() {
      const userData = getUserData()

      axios
        .post('', {
          query: `
             query {
          allClients(clientId:"${userData.profile.client.clientId}"){
          edges {
              node {
                id
                cdnTrafficLimit
                sizeFileLimit
                
              }
            }
          }
          }     `,
        }).then(result => {
          messageError(result, this)

          this.cdnTrafficLimit = result.data.data.allClients.edges[0].node.cdnTrafficLimit
          this.sizeFileLimit = result.data.data.allClients.edges[0].node.sizeFileLimit

          const fecha = new Date()
          const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
          this.actualMonth = meses[fecha.getMonth()]

          this.getMonth()
          this.fetchData()
        }).catch(err => {
          console.log(err)
        })
    },
    clickCalendar() {
      document.getElementById('calendar').click()
    },
    fetchData() {
      const userData = getUserData()
      axios
        .post('', {
          query: `
             query {
        getKpi (client:"${userData.profile.client.id}"){
          edges {
            node {
              id
              name
              namespace
              dimensionName
              dimensionValue
              dimensionName2
              dimensionValue2
              chartName
              awsKpiType
              serverUrl
              apikey
              stat
              client {
                id
              }
            }
          }
        }
        }     `,
        })
        .then(result => {
          messageError(result, this)

          this.buscado = true
          this.awsKpi = result.data.data.getKpi.edges
          const valorArray = []
          this.awsKpi.forEach(element => {
            if (element.node.awsKpiType === 'VAL') valorArray.push(element.node)
            else this.graphic.push(element.node)
          })

          valorArray.forEach(element => {
            const key = element.chartName

            if (!this.chartName.includes(key)) {
              this.chartName.push(key)
              this.totalName[key] = 1
              this.resName[key] = 1
            } else {
              this.totalName[key] += 1
              this.resName[key] += 1
            }
          })
          this.graphicsObj = [{}]
          let ar = []
          let guardar = false

          this.chartName.forEach(element => {
            guardar = false
            ar = []
            this.graphic.forEach(a => {
              if (element === a.chartName) {
                guardar = true
                ar.push(a)
              }
            })
            if (guardar) this.graphicsObj[element] = ar
          })

          this.value = valorArray
        })
        .catch(err => {
          console.log(err)
        })
    },
    getPeriodDate() {
      try {
        const timeEnd = new Date(
          this.endDate.getFullYear(),
          this.endDate.getMonth(),
          this.endDate.getDate(),
        )
        let timeStart = new Date(
          this.startDate.getFullYear(),
          this.startDate.getMonth(),
          this.startDate.getDate(),
        )

        if (timeEnd.getTime() === timeStart.getTime()) {
          const start = timeStart
          start.setHours(0, 0, 0, 0)
          timeStart = start
        }

        timeEnd.setDate(timeEnd.getDate() + 1)

        let period = timeEnd.getTime() - timeStart.getTime()
        period /= 1000
        if (period % 60) period += 60 - (period % 60)

        if (period <= 86400) period = 86400

        this.period = period
      } catch (err) {
        console.log(err)
      }
    },
    getMonth() {
      try {
        const inicio = new Date()
        inicio.setDate(1)

        this.startDate = new Date(inicio.setHours(0, 0, 0, 0))

        const fin = new Date()
        this.endDate = fin

        this.getPeriodDate()
      } catch (err) {
        console.log(err)
      }
    },
    getTimeNow() {
      const start = new Date()

      start.setHours(0, 0, 0)

      this.startDate = start
      const i = new Date()
      this.endDate = i

      this.getPeriodDate()
    },

    getTime(dias) {
      try {
        const i = new Date()
        this.endDate = new Date(i)
        const subtractTimeFromDate = function (objDate, intHours) {
          const numberOfMlSeconds = objDate.getTime()
          const addMlSeconds = (intHours * 60) * 60000
          const newDateObj = new Date(numberOfMlSeconds - addMlSeconds)

          return newDateObj
        }
        this.startDate = subtractTimeFromDate(this.endDate, (dias * 24))
        this.startDate = new Date(this.startDate.setHours(0, 0, 0, 0))

        this.getPeriodDate()
      } catch (err) {
        console.log(err)
      }
    },
    checkTotal(valor) {
      let cont = 0
      this.value.forEach(e => {
        if (e.chartName === valor) {
          cont += 1
        }
      })
      return cont > 1
    },
    sumTot(data) {
      const sumatorio = data[0]
      const key = data[1]

      if (this.$data[key] == null) {
        this.$data[key] = sumatorio

        this.resName[key] -= data[2]
        this.renderiza += 1
      } else {
        this.resName[key] -= data[2]
        this.$data[key] += sumatorio
        this.renderiza += 1
      }
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },
    bytesToSize(bytes) {
      if (bytes === null) return '--'
      if (bytes === 0) return '0 Bytes'
      if (bytes === 1) return '1 Byte'
      if (bytes === -1) return '-1 Byte'

      const byte = Math.abs(bytes)

      const orderOfMagnitude = (10 ** 3)
      const abbreviations = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(byte) / Math.log(orderOfMagnitude))
      let result = (byte / (orderOfMagnitude ** i))

      if (byte < 0) {
        result *= -1
      }

      if (result >= 99.995 || i === 0) {
        return `${result.toFixed(0)} ${abbreviations[i]}`
      }
      return `${result.toFixed(2)} ${abbreviations[i]}`
    },

  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#cloudwatch #Balance {
  position: relative;
  box-sizing: border-box;
  padding: 1em 1em 1.5em 1em;
  min-height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(150px, 1fr);
  grid-gap: 1em;
  grid-auto-flow: row dense;
}

#cloudwatch .updated {
  font: 1em "Nunito";
  padding-right: 1em;
  line-height: 1.5em;
  position: absolute;
  bottom: 0;
  right: 0;
}

#cloudwatch .tarjeta {
  display: flex;
  flex-direction: row;
  width: 100%;
  // justify-content: center;
}

#cloudwatch .cartas {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align-last: center;
  border-radius: 20px 20px 20px 20px;
  border: 2px solid #7367f0;
}

#cloudwatch .widthCard {
  max-width: 100%;
  width: -webkit-fill-available;
}

/*
.b-overlay .position-absolute div {
  text-align: center !important;
  background-color: red !important;
} */
</style>
